@import "styles/variables";

.servicesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $PADDING-XXL 0;
  background-color: $WHITE;

  @include tablet-landscape {
    margin-top: 2.5rem;
  }

  .title {
    margin-bottom: 3rem;
    color: $MARINE;
    font-size: $FONT-SIZE-XXL;
    font-weight: $FONT-WEIGHT-MEDIUM;
  }

  .services {
    display: grid;
    width: 100%;
    gap: 2.8rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);

    @include tablet-portrait {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    .card-1 {
      grid-column: 1 / span 4;
      grid-row: 1 / span 1;

      @include tablet-portrait {
        grid-column: 2 / span 5;
        grid-row: 1 / span 1;
      }

      @include tablet-landscape {
        grid-column: 3 / span 4;
        grid-row: 1 / span 1;
      }

      @include desktop {
        grid-column: 1 / span 3;
        grid-row: 1 / span 1;
      }
    }

    .card-2 {
      grid-column: 1 / span 4;
      grid-row: 2 / span 1;

      @include tablet-portrait {
        grid-column: 7 / span 5;
        grid-row: 1 / span 1;
      }

      @include tablet-landscape {
        grid-column: 7 / span 4;
        grid-row: 1 / span 1;
      }

      @include desktop {
        grid-column: 4 / span 3;
        grid-row: 1 / span 1;
      }
    }

    .card-3 {
      grid-column: 1 / span 4;
      grid-row: 3 / span 1;

      @include tablet-portrait {
        grid-column: 2 / span 5;
        grid-row: 2 / span 1;
      }

      @include tablet-landscape {
        grid-column: 3 / span 4;
        grid-row: 2 / span 1;
      }

      @include desktop {
        grid-column: 7 / span 3;
        grid-row: 1 / span 1;
      }
    }

    .card-4 {
      grid-column: 1 / span 4;
      grid-row: 4 / span 1;

      @include tablet-portrait {
        grid-column: 7 / span 5;
        grid-row: 2 / span 1;
      }

      @include tablet-landscape {
        grid-column: 7 / span 4;
        grid-row: 2 / span 1;
      }

      @include desktop {
        grid-column: 10 / span 3;
        grid-row: 1 / span 1;
      }
    }
  }
}
