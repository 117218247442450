@import "styles/variables";

.agenciesSectionColor {
  background-color: $WHITE-GREY;
}

.agenciesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  padding: 4rem 0;

  @include tablet-landscape {
    flex-direction: row;
    align-items: stretch;
    padding: 4rem 0;
  }

  @include desktop {
    padding: 4rem 0;
  }

  .agenciesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet-landscape {
      align-items: flex-start;
      width: calc(50% - 3.5rem);
      margin-right: 3.5rem;
    }

    h2 {
      margin-top: $MARGIN-XL;
      color: $MARINE;
      font-size: $FONT-SIZE-XXL;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      text-align: center;
    }

    .agenciesDescription {
      padding: $PADDING-XXL 0;
      color: $GUNMETAL;
      font-size: $FONT-SIZE-S;
      text-align: left;
    }

    .agenciesLinks {
      display: flex;
      width: 100%;
      flex-flow: row wrap;

      .agencyLink {
        width: 100%;
        margin-bottom: $MARGIN-XL;
        color: $MARINE;
        font-size: $FONT-SIZE-S;
        font-weight: $FONT-WEIGHT-MEDIUM;
        text-align: left;

        &:hover {
          text-decoration: underline;
        }

        @include tablet-landscape {
          width: fit-content;
          margin-right: 3.2rem;
        }
      }
    }
  }

  .imageBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    @include tablet-landscape {
      justify-content: flex-end;
      width: calc(50% - 3.5rem);
      margin-left: 3.5rem;
    }

    .imageWrapper {
      width: 100%;
      height: 25rem;
      margin-bottom: 1.5rem;
      position: relative;
    }

    .findYourAgency {
      width: 100%;
      padding: $PADDING-XL 0;
      color: $WHITE;
      background-color: $OCEAN-BLUE;
      font-size: $FONT-SIZE-S;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      border-radius: 0.8rem;
      letter-spacing: 0.14rem;
      text-align: center;
      text-transform: uppercase;

      &:hover {
        background-color: $MARINE-LIGHT;
      }

      @include tablet-landscape {
        width: fit-content;
        margin-top: $MARGIN-XL;
        padding: $PADDING-XL $PADDING-XXL;
        align-self: flex-end;
      }
    }
  }
}
