@import "styles/variables";

.learnMoreBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  background-color: $WHITE;

  .sectionTitle {
    margin-bottom: 3rem;
    color: $MARINE;
    font-size: 2.8rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .paragraphGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 3rem;
    column-gap: 2.8rem;

    @include tablet-landscape {
      grid-template-columns: repeat(2, 1fr);
    }

    .paragraphTitle {
      margin-bottom: 1.5rem;
      color: $MARINE;
      font-size: 1.5rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
    }

    .paragraphText {
      color: $GUNMETAL;
      font-size: 1.5rem;
      text-align: justify;
    }
  }
}
