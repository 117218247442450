@import "styles/variables";

.container {
  width: 29.5rem;

  .category {
    margin-bottom: $MARGIN-L;
    color: $MARINE;
    font-size: $FONT-SIZE-XL;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
    text-align: center;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border: 0.1rem solid $LIGHT-PERIWINKLE;
    border-radius: 0.6rem;

    .imageContainer {
      width: 100%;
      height: 11rem;
      position: relative;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 5rem;
      color: $OCEAN-BLUE;
      font-size: 2rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      text-align: center;
    }

    .separator {
      width: 4.3rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 0.2rem solid $CHERRY-RED;
    }

    .subtitle {
      color: #a3abbd;
      font-size: $FONT-SIZE-XXS;
      font-weight: $FONT-WEIGHT-BOLD;
    }

    .description {
      display: -webkit-box;
      height: 9.4rem;
      margin-top: 1rem;
      padding: 1rem 0;
      color: $GUNMETAL;
      font-size: $FONT-SIZE-S;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }

    .cta {
      margin-top: $MARGIN-XL;
      color: $MARINE;
      font-size: $FONT-SIZE-XS;
      font-weight: $FONT-WEIGHT-BOLD;
      align-self: flex-end;
      cursor: pointer;
    }

    &:hover {
      box-shadow: 0 0.3rem 1rem 0 rgb(63 71 86 / 15%);

      & > .cta {
        text-decoration: underline;
      }
    }
  }
}
