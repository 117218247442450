@import "styles/variables";

.vehiclesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  background-color: $WHITE;

  @include tablet-landscape {
    padding: 4rem 0;
  }

  @include desktop {
    padding: 4rem 0;
  }

  .title {
    margin-bottom: 3rem;
    color: $MARINE;
    font-size: 2.8rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
    text-align: center;
  }

  .description {
    color: $GUNMETAL;
    font-size: $FONT-SIZE-S;
    text-align: center;
  }

  .cardsGrid {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    column-gap: 2.8rem;
    row-gap: 3rem;

    @include tablet-portrait {
      justify-content: center;
      flex-flow: row wrap;
    }

    @include desktop {
      flex-direction: row;
    }
  }
}
