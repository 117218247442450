@import "styles/variables";

.cardContainer {
  border: 0.1rem solid $LIGHT-PERIWINKLE;
  border-radius: 0 0 0.6rem 0.6rem;
  cursor: pointer;

  .imageWrapper {
    width: 100%;
    height: 20rem;
    position: relative;
  }

  .separator {
    width: 100%;
    height: 0.5rem;
    background-color: $OCEAN-BLUE;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    padding: 2rem $PADDING-XL;

    .category {
      margin-top: $MARGIN-L;
      font-size: 1.4rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      text-transform: uppercase;
    }

    .title {
      padding: 1rem 0;
      color: $MARINE;
      font-size: 2rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      white-space: nowrap;
    }

    .description {
      display: -webkit-box;
      height: 9.4rem;
      padding: 1rem 0;
      color: $GUNMETAL;
      font-size: $FONT-SIZE-S;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }

    .cta {
      margin-top: 0.5rem;
      color: $MARINE;
      font-size: 1.4rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      letter-spacing: 0.14rem;
      text-align: right;
      text-transform: uppercase;
    }
  }

  &:hover {
    box-shadow: 0.3rem 0.3rem 2rem 0 rgb(63 71 86 / 15%);

    & > .cardContent > .cta {
      text-decoration: underline;
    }
  }
}

.pro {
  color: $LIPSTICK;
}

.particulier {
  color: $OCEAN-BLUE;
}
